import React from "react";
import Home from "../../Components/Home";

const MainPage = () => {
  return (
    <div>
      <Home />
    </div>
  );
};

export default MainPage;
